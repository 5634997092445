
import {useEffect} from "react";
import {ethers} from "ethers";
import AccountOption from "./accountOption";
import jQuery1 from "jquery";


export default function ConnectWallet({account, setAccount}) {

    useEffect(() => {
        loadWalletAddress()
    }, [])

    async function loadWalletAddress() {

        /* Load if already connected but don't prompt the user if not */
        if (window.ethereum) {
            try {
                const provider = new ethers.providers.Web3Provider(window.ethereum)
                const accounts = await provider.listAccounts()
                console.log('accounts ', accounts)
                setAccount(accounts[0])
            } catch (e) {
                // todo
                console.log(e)
            }
        }
    }

    async function connectWallet() {

        if (window.ethereum) {
            try {
                const accounts = await window.ethereum.request({
                    method: "eth_requestAccounts"
                })
                setAccount(accounts[0])
            } catch (e) {
                // todo
                console.log(e)
            }
        }
    }

    function toggleWalletInfoModal(){
        if( jQuery1('#myWalletAccount').hasClass('show') ){
            jQuery1('#myWalletAccount').removeClass('show')
        } else {
            jQuery1('#myWalletAccount').addClass('show')
        }
    }

    return (
        <>
            {!account && <input id="connect" className="default-btn enableEthereumButton"
                                type="button"
                                value="Connect Wallet"
                                onClick={() => {connectWallet()}}
            />}
            {account &&
                <>
                    <a id="showWalletOption" onClick={toggleWalletInfoModal} className="default-btn cursor wallet-profile-button">My Wallet</a>
                        <AccountOption account={account} />
                </>
            }
        </>
    )

}
