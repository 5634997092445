import ClaimNFTs from "./claimNFTs";
import Collection from "./collection";


export default function Home({account, setAccount}) {

    return (
        <>
            <section className="banner padding-top padding-bottom bg__primary">
                <div className="container">
                    <div className="banner__wrapper">
                        <div className="row g-5">
                            <div className="col-lg-6" style={{paddingTop: "10%"}}>
                                <div className="about__content" data-aos="fade-up" data-aos-duration="1000">
                                    <h1>Introducing <span className="gradient">Sakoso Monsters</span></h1>
                                    <p>A curious breed of ancient creatures that have been around since the beginning of
                                        time. Each Sakoso Monster is different in personality and appearance, but they
                                        all share one thing in common: insatiable curiosity.</p>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <ClaimNFTs account={account}/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="about padding-top padding-bottom">
                <div className="container">
                    <div className="about__wrapper">
                        <div className="row g-5 align-items-center">
                            <div className="col-lg-6">
                                <div className="about__thumb" data-aos="fade-zoom-in" data-aos-duration="1000">
                                    <img src="images/unique-sakoso-monsters.png" alt="About Image"/>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="about__content" data-aos="fade-up" data-aos-duration="1000">
                                    <h2>5000 Unique <br/> Sakoso Monster NFTs</h2>

                                    <p>The Sakoso Monsters were released from their ancient prison and they were excited
                                        to explore the modern world! While some preferred to live a wholesome life full
                                        of travel and love, others decided upon a life of crime and an unsatiable amount
                                        of partying. No one could stop them – they were wild and free!</p>

                                    <p>But as the years went on, something began to change within the monsters. Some
                                        started to long for a more peaceful life, while others just couldn’t get enough
                                        of the fun and excitement. The divide between those who wanted peace and those
                                        who wanted chaos grew larger with each day.</p>

                                    <p>One night, after a particularly wild party, the monsters who wanted peace reached
                                        their breaking point.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="about padding-top padding-bottom bg__primary">
                <div className="container">
                    <div className="about__wrapper">
                        <div className="row g-5 align-items-center">
                            <div className="col-lg-6">
                                <div className="about__content" data-aos="fade-up" data-aos-duration="1000">
                                    <h2>Culture Shocked Sakoso Monsters</h2>

                                    <p>They fought back against those who wanted to continue the party, and in the end,
                                        the monsters who craved peace won and could stake their place in society. The
                                        Sakoso Monsters who longed for chaos were banished to a place where they could
                                        party to their heart’s content: The Island of Misfit Monsters.</p>

                                    <p>The Island of Misfit Monsters is a place where the Sakoso Monsters can go to let
                                        off some steam and party all they want, without bothering those who just want to
                                        live in peace.</p>

                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="about__thumb" data-aos="fade-up" data-aos-duration="1500">
                                    <img src="images/more-monsters.png" alt="About Image"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="roadmap padding-top padding-bottom" id="roadmap">
                <div className="container">
                    <div className="section-header" data-aos="fade-zoom-in" data-aos-duration="1000">
                        <h2>Growth Roadmap</h2>
                        <p>The Sakoso Monster project has been built to learn and contribute to the NFT community. The
                            aim is to create a valuable NFT project that can adapt and grow to the holders vision for
                            the project.</p>
                    </div>
                    <div className="roadmap__wrapper">
                        <div className="row justify-content-center">
                            <div className="col-lg-9 col-12">
                                <div className="roadmap__item" data-aos="fade-zoom-in" data-aos-duration="1000">
                                    <div className="roadmap__inner">
                                        <div className="roadmap__thumb">
                                            <span>10%</span>
                                        </div>
                                        <div className="roadmap__content">
                                            <h4>1. Launch Initiated</h4>

                                            <p>The initial launch of Sakoso Monsters will be a collection of 5,000 NFTs.
                                                Built on the Polygon blockchain, Sakoso Monsters have been created to
                                                learn how an NFT drop works in accordance with Metamask and Opensea.</p>

                                            <p>Sakoso Monsters can be held for further utility and traded via Opensea.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-9 col-12">
                                <div className="roadmap__item" data-aos="fade-zoom-in" data-aos-duration="1200">
                                    <div className="roadmap__inner">
                                        <div className="roadmap__thumb roadmap__thumb--bg-2">
                                            <span>20%</span>
                                        </div>
                                        <div className="roadmap__content">
                                            <h4>2. Staking</h4>
                                            <p>Holders of Sakoso Monsters will be able to log in to a member's area of
                                                this website and directly make offers and trade their Sakoso Monsters
                                                with other holders. This will include Opensea integration.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-9 col-12">
                                <div className="roadmap__item" data-aos="fade-zoom-in" data-aos-duration="1400">
                                    <div className="roadmap__inner">
                                        <div className="roadmap__thumb roadmap__thumb--bg-3">
                                            <span>40%</span>
                                        </div>
                                        <div className="roadmap__content">
                                            <h4>3. Physical Merch</h4>
                                            <p>Within the member's area, an eCommerce store will become available,
                                                giving holders of Sakoso Monsters access to exclusive physical
                                                merchandise to purchase and receive.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-9 col-12">
                                <div className="roadmap__item" data-aos="fade-zoom-in" data-aos-duration="1600">
                                    <div className="roadmap__inner">
                                        <div className="roadmap__thumb roadmap__thumb--bg-4">
                                            <span>60%</span>
                                        </div>
                                        <div className="roadmap__content">
                                            <h4>4. The Sakoso Monster</h4>
                                            <p>We want the Sakoso Monster community to have an active say on the
                                                direction and growth of the project. A discord group would be set up
                                                with an active discussion on the direction of the project for the
                                                future.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-9 col-12">
                                <div className="roadmap__item no-border" data-aos="fade-zoom-in"
                                     data-aos-duration="1800">
                                    <div className="roadmap__inner">
                                        <div className="roadmap__thumb roadmap__thumb--bg-5">
                                            <span>100%</span>
                                        </div>
                                        <div className="roadmap__content">
                                            <h4>5.Metaverse</h4>
                                            <p>We never want to overpromise, let’s see, maybe one day. Let’s see how the
                                                community grows for the Sakoso Monsters.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="team padding-top padding-bottom" id="team">
                <div className="container">
                    <div className="section-header" data-aos="fade-zoom-in" data-aos-duration="1000">
                        <h2>Meet The Team</h2>
                        <p>Entering The Cute Monster Society means joining an family full of people who believe
                            in the future of Cryptocurrencies and the Blockchain technology.</p>
                    </div>
                    <div className="team__wrapper">
                        <div className="row g-4">
                            <div className="col-lg-3 col-sm-6">
                                <div className="team__item" data-aos="fade-up" data-aos-duration="500">
                                    <div className="team__inner">
                                        <div className="team__thumb">
                                            <img src="images/team/01.png" alt="team Image"/>
                                        </div>
                                        <div className="team__content">
                                            <h4><a href="#">Tom Blackburn</a></h4>
                                            <p>UI/UX Designer</p>
                                            <a href="#">
                                                <img src="images/team/insta.png" alt="insta"/>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-sm-6">
                                <div className="team__item" data-aos="fade-up" data-aos-duration="1000">
                                    <div className="team__inner">
                                        <div className="team__thumb">
                                            <img src="images/team/02.png" alt="team Image"/>
                                        </div>
                                        <div className="team__content">
                                            <h4><a href="#">Jessica Start</a></h4>
                                            <p>Front End Designer</p>
                                            <a href="#">
                                                <img src="images/team/discord.png" alt="discord"/>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-sm-6">
                                <div className="team__item" data-aos="fade-up" data-aos-duration="1500">
                                    <div className="team__inner">
                                        <div className="team__thumb">
                                            <img src="images/team/03.png" alt="team Image"/>
                                        </div>
                                        <div className="team__content">
                                            <h4><a href="#">Mei Li</a></h4>
                                            <p>UI/UX Designer</p>
                                            <a href="#">
                                                <img src="images/team/youtube.png" alt="youtube"/>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-sm-6">
                                <div className="team__item" data-aos="fade-up" data-aos-duration="2000">
                                    <div className="team__inner">
                                        <div className="team__thumb">
                                            <img src="images/team/04.png" alt="team Image"/>
                                        </div>
                                        <div className="team__content">
                                            <h4><a href="#">David Clark</a></h4>
                                            <p>Web Designer </p>
                                            <a href="#">
                                                <img src="images/team/twitter.png" alt="twitter"/>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="artist padding-top padding-bottom" id="artist">
                <div className="container">
                    <div className="artist__wrapper">
                        <div className="row g-5 align-items-center">
                            <div className="col-lg-6">
                                <div className="artist__thumb" data-aos="fade-right" data-aos-duration="1000">
                                    <img src="images/artist/01.png" alt="Artist Image"/>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="artist__content" data-aos="fade-left" data-aos-duration="1500">
                                    <h2 className="mb-4">Artist - Tom Blackburn</h2>
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptas de leniti
                                        within
                                        voluptatibus officia! Ipsum porro repellendus quis fuga illooxe officia, amet
                                        rem culpa
                                        quidem magni incidunt vitae accusamus.</p>
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptas de leniti
                                        within
                                        voluptatibus officia! Ipsum porro repellendus quis fuga illooxe officia, amet
                                        rem culpa
                                        quidem magni incidunt vitae accusamus.</p>
                                    <p className="mb-5">Lorem ipsum dolor sit amet consectetur adipisicing elit.
                                        Voluptas de leniti
                                        within voluptatibus officia! Ipsum porro repellendus quis fuga illooxe officia,
                                        amet rem
                                        culpa quidem magni incidunt vitae accusamus.</p>
                                    <a href="#" className="default-btn default-btn--rounded">
                                        <span>More About The Artist</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="community padding-top padding-bottom bg__primary">
                <div className="container">
                    <div className="community__wrapper" data-aos="zoom-in" data-aos-duration="1000">
                        <div className="community__content text-center">
                            <h2>Join The Sakoso Discord</h2>
                            <p>Join a genuine community of Sakoso holders</p>
                            <a href="#" className="default-btn default-btn--rounded"><span>Join Our Discord</span></a>
                        </div>
                    </div>
                </div>
            </section>

            <section id="faq" className="faq padding-top padding-bottom">
                <div className="container">
                    <div className="section-header" data-aos="fade-zoom-in" data-aos-duration="1000">
                        <h2>Frequently Asked Questions</h2>
                        <p>Entering The Cute Monster Society means joining an family full of people who believe
                            in the future of Cryptocurrencies and the Blockchain technology.</p>
                    </div>
                    <div className="faq__wrapper">
                        <div className="row justify-content-center">
                            <div className="col-lg-8">
                                <div className="accordion" id="faqAccordion2">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="accordion__item" data-aos="fade-zoom-in"
                                                 data-aos-duration="600">
                                                <div className="accordion__header" id="faq1">
                                                    <button className="accordion__button collapsed" type="button"
                                                            data-bs-toggle="collapse" data-bs-target="#faqBody1"
                                                            aria-expanded="false" aria-controls="faqBody1">
                                                        01
                                                        When is the official launch? <span className="plus-icon"></span>
                                                    </button>
                                                </div>
                                                <div id="faqBody1" className="accordion-collapse collapse"
                                                     aria-labelledby="faq1"
                                                     data-bs-parent="#faqAccordion2">
                                                    <div className="accordion__body">
                                                        We want provide our community with the best quality art from
                                                        real artist
                                                        for afordable price
                                                        That is why we chose mint price of 0.22 ETH (+ gas fee). If you
                                                        are
                                                        planning to hold your Bulls
                                                        you will bee rewarded with FEE Cute Monster approximatey 6-8
                                                        weeks after
                                                        the official launch
                                                        02
                                                        What will the mint price be?.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="accordion__item" data-aos="fade-zoom-in"
                                                 data-aos-duration="800">
                                                <div className="accordion__header" id="faq2">
                                                    <button className="accordion__button" type="button"
                                                            data-bs-toggle="collapse"
                                                            data-bs-target="#faqBody2" aria-expanded="true"
                                                            aria-controls="faqBody2">
                                                        02
                                                        What will the mint price be? <span className="plus-icon"></span>
                                                    </button>
                                                </div>
                                                <div id="faqBody2" className="accordion-collapse collapse show"
                                                     aria-labelledby="faq2" data-bs-parent="#faqAccordion2">
                                                    <div className="accordion__body">
                                                        We want provide our community with the best quality art from
                                                        real artist
                                                        for afordable price
                                                        That is why we chose mint price of 0.22 ETH (+ gas fee). If you
                                                        are
                                                        planning to hold your Bulls
                                                        you will bee rewarded with FEE Cute Monster approximatey 6-8
                                                        weeks after
                                                        the official launch
                                                        02
                                                        What will the mint price be?.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="accordion__item" data-aos="fade-zoom-in"
                                                 data-aos-duration="1000">
                                                <div className="accordion__header" id="faq3">
                                                    <button className="accordion__button collapsed" type="button"
                                                            data-bs-toggle="collapse" data-bs-target="#faqBody3"
                                                            aria-expanded="false" aria-controls="faqBody3">
                                                        03
                                                        How does the raffle work?<span className="plus-icon"></span>
                                                    </button>
                                                </div>
                                                <div id="faqBody3" className="accordion-collapse collapse"
                                                     aria-labelledby="faq3"
                                                     data-bs-parent="#faqAccordion2">
                                                    <div className="accordion__body">
                                                        We want provide our community with the best quality art from
                                                        real artist
                                                        for afordable price
                                                        That is why we chose mint price of 0.22 ETH (+ gas fee). If you
                                                        are
                                                        planning to hold your Bulls
                                                        you will bee rewarded with FEE Cute Monster approximatey 6-8
                                                        weeks after
                                                        the official launch
                                                        03
                                                        How does the raffle work?
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="accordion__item" data-aos="fade-zoom-in"
                                                 data-aos-duration="1200">
                                                <div className="accordion__header" id="faq4">
                                                    <button className="accordion__button collapsed" type="button"
                                                            data-bs-toggle="collapse" data-bs-target="#faqBody4"
                                                            aria-expanded="false" aria-controls="faqBody4">
                                                        04
                                                        How do I get a FREE Cute Monster?<span
                                                        className="plus-icon"></span>
                                                    </button>
                                                </div>
                                                <div id="faqBody4" className="accordion-collapse collapse"
                                                     aria-labelledby="faq4"
                                                     data-bs-parent="#faqAccordion2">
                                                    <div className="accordion__body">
                                                        We want provide our community with the best quality art from
                                                        real artist
                                                        for afordable price
                                                        That is why we chose mint price of 0.22 ETH (+ gas fee). If you
                                                        are
                                                        planning to hold your Bulls
                                                        you will bee rewarded with FEE Crypto Hero approximatey 6-8
                                                        weeks after
                                                        the official launch
                                                        04
                                                        How do I get a FREE Cute Monster?
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="accordion__item" data-aos="fade-zoom-in"
                                                 data-aos-duration="1400">
                                                <div className="accordion__header" id="faq5">
                                                    <button className="accordion__button collapsed" type="button"
                                                            data-bs-toggle="collapse" data-bs-target="#faqBody5"
                                                            aria-expanded="false" aria-controls="faqBody5">
                                                        05
                                                        How do I get whitelisted?<span className="plus-icon"></span>
                                                    </button>
                                                </div>
                                                <div id="faqBody5" className="accordion-collapse collapse"
                                                     aria-labelledby="faq5"
                                                     data-bs-parent="#faqAccordion2">
                                                    <div className="accordion__body">
                                                        We want provide our community with the best quality art from
                                                        real artist
                                                        for afordable price
                                                        That is why we chose mint price of 0.22 ETH (+ gas fee). If you
                                                        are
                                                        planning to hold your Bulls
                                                        you will bee rewarded with FEE Cute Monster approximatey 6-8
                                                        weeks after
                                                        the official launch
                                                        05
                                                        How do I get whitelisted?
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="accordion__item" data-aos="fade-zoom-in"
                                                 data-aos-duration="1600">
                                                <div className="accordion__header" id="faq6">
                                                    <button className="accordion__button collapsed" type="button"
                                                            data-bs-toggle="collapse" data-bs-target="#faqBody6"
                                                            aria-expanded="false" aria-controls="faqBody6">
                                                        06
                                                        How can I use my NFT ?<span className="plus-icon"></span>
                                                    </button>
                                                </div>
                                                <div id="faqBody6" className="accordion-collapse collapse"
                                                     aria-labelledby="faq6"
                                                     data-bs-parent="#faqAccordion2">
                                                    <div className="accordion__body">
                                                        We want provide our community with the best quality art from
                                                        real artist
                                                        for afordable price
                                                        That is why we chose mint price of 0.22 ETH (+ gas fee). If you
                                                        are
                                                        planning to hold your Bulls
                                                        you will bee rewarded with FEE Cute Monster approximatey 6-8
                                                        weeks after
                                                        the official launch
                                                        06
                                                        How can I use my NFT ?
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <section id="marketplace" >
                <Collection />
            </section> */}

        </>
    )

}
