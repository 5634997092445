import './App.css';
import {Link, Route, Routes} from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import Home from "./components/home";
import Collection from "./components/collection";

import './css/all.min.css';
import './css/aos.css';
import './css/bootstrap.min.css';
import './css/style.css';
import './css/swiper-bundle.min.css';
import {useState} from "react";
import ConnectWallet from "./components/connectWallet";


function App() {

    const [account, setAccount] = useState()

    return (
        <div className="App">

            <div className="preloader">
                <img src="images/logo/preloader.png" alt="CuteMonster"/>
            </div>

            <header className="header">
                <div className="container">
                    <nav className="navbar navbar-expand-xl">
                        <h3>Sakoso Monsters</h3>
                        <button className="navbar-toggler collapsed" type="button" data-bs-toggle="collapse"
                                data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup"
                                aria-expanded="false"
                                aria-label="Toggle navigation">
                            <span className="navbar-toggler--icon"></span>
                        </button>
                        <div className="collapse navbar-collapse justify-content-end" id="navbarNavAltMarkup">
                            <div className="navbar-nav">
                                <HashLink className="nav-link" to='/#roadmap'>Roadmap</HashLink>
                                <HashLink className="nav-link" to='/#artist'>Artist</HashLink>
                                <HashLink className="nav-link" to='/#team'>Team</HashLink>
                                <HashLink className="nav-link" to='/#faq'>FAQ</HashLink>
                                {/* <HashLink className="nav-link" to='/#marketplace'>Marketplace</HashLink> */}
                                <a className="nav-link" onClick={() => {window.location.href="/marketplace.html"}}>Marketplace</a>
                                {/* <Link  className="nav-link" to="/marketplace.html">Marketplace</Link> */}
                            </div>
                            <div className="social-btns">
                                <div className="account-section">
                                    <ConnectWallet account={account} setAccount={setAccount}/>
                                </div>
                            </div>

                        </div>
                    </nav>
                </div>

            </header>


            <main>
                <Routes>
                    <Route path="/" element={<Home account={account} />} />
                    <Route path="collection" element={<Collection />} />
                </Routes>
            </main>


            <footer className="footer bg__primary">
                <div className="footer__wrapper padding-top padding-bottom">
                    <div className="container">
                        <div className="footer__content text-center">
                            <h3>Sakoso Monsters</h3>
                            <p>5000 Unique NFT Items</p>
                            <ul className="social justify-content-center">
                                <li className="social__item">
                                    <a href="#" className="social__link"><i className="fab fa-twitter"></i></a>
                                </li>
                                <li className="social__item">
                                    <a href="#" className="social__link"><i className="fab fa-discord"></i></a>
                                </li>
                                <li className="social__item">
                                    <a href="#" className="social__link"><i className="fab fa-twitch"></i></a>
                                </li>
                                <li className="social__item">
                                    <a href="#" className="social__link"><i className="fab fa-instagram"></i></a>
                                </li>
                            </ul>
                            <br/>
                            <p><a href="https://www.cudedesign.co.uk/blockchain-and-nft-agency/" rel="nofollow">NFT
                                Website by Cude Design</a></p>
                        </div>
                    </div>
                </div>
            </footer>

        </div>
    );
}

export default App;
