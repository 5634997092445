import { CHOSEN_THEME, OPENSEA_ASSET_URL } from "./../constants.js";
import IframeResizer from "iframe-resizer-react";
import { useEffect } from "react";
import jQuery1 from "jquery";

export default function Collection() {
  const embeddedUrl = `${OPENSEA_ASSET_URL}?embed=${CHOSEN_THEME}`;

  // componentDidMount
  useEffect(() => {
    // fill columns according to your data
    jQuery1('.select-down').click(function () {
        jQuery1(this).toggleClass('show');
        jQuery1(this).children('.dropdown-menu').toggleClass('show');
    });
  }, []);

  useEffect(() => {
    // componentWillUnmount
    return () => {
        jQuery1('.select-down').click(function () {
            jQuery1(this).toggleClass('show');
            jQuery1(this).children('.dropdown-menu').toggleClass('show');
        });
    }
  }, []);

  return (
    <>
      {/* <IframeResizer
                heightCalculationMethod="lowestElement"
                inPageLinks
                log
                src={embeddedUrl}
                style={{ width: '1px', minWidth: '100%'}}
            /> */}
      <div class="pg-bg">
        <section class="page-heading">
          <div class="container">
            <div class="section-heading">
              <div class="row">
                <div class="col-lg-12">
                  <h1>Marketplace</h1>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="filters-section">
          <div class="container">
            <div class="filters-row">
              <div class="row">
                <div class="col-md-3 col-lg-3">
                  <h3>Filters</h3>

                  <form name="" class="" action="">
                    <div class="filter-item toggle selector">
                      <h4>Versions</h4>
                      {/* <ul>
                        <li>
                          <a href="#" class="button">
                            EDITIONS
                          </a>
                        </li>
                        <li>
                          <a href="#" class="button">
                            RELEASES
                          </a>
                        </li>
                      </ul> */}
                      <div class="selector-wrapper">
                        <input
                          type="radio"
                          name="select"
                          id="option-1"
                          checked
                        />
                        <input type="radio" name="select" id="option-2" />
                        <label for="option-1" class="option option-1">
                          <span>EDITIONS</span>
                        </label>
                        <label for="option-2" class="option option-2">
                          <span>RELEASES</span>
                        </label>
                      </div>
                    </div>

                    <div class="filter-item toggle-close checkbox">
                      <h4>Traits</h4>
                      <ul class="checklist">
                        <li>
                          <input id="" type="checkbox" name="" value="" />
                          <label for="1">Space</label>
                        </li>
                        <li>
                          <input id="" type="checkbox" name="" value="" />
                          <label for="">Trippy</label>
                        </li>
                        <li>
                          <input id="" type="checkbox" name="" value="" />
                          <label for="">Rainbow</label>
                        </li>
                        <li>
                          <input id="" type="checkbox" name="" value="" />
                          <label for="">Crypto Bro</label>
                        </li>
                        <li>
                          <input id="" type="checkbox" name="" value="" />
                          <label for="">Flames</label>
                        </li>
                        <li>
                          <input id="" type="checkbox" name="" value="" />
                          <label for="">Love Eyes</label>
                        </li>
                        <li>
                          <input id="" type="checkbox" name="" value="" />
                          <label for="">Spaced Eyes</label>
                        </li>
                      </ul>
                    </div>

                    <div class="filter-item toggle price">
                      <h4>Price</h4>
                      <div class="dropdown select-down">
                        <button
                          class="btn btn-secondary dropdown-toggle"
                          type="button"
                          id="dropdownMenu2"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          ETH
                        </button>
                        <div
                          class="dropdown-menu"
                          aria-labelledby="dropdownMenu2"
                        >
                          <button class="dropdown-item" type="button">
                            ETH
                          </button>
                          <button class="dropdown-item" type="button">
                            MATIC
                          </button>
                          <button class="dropdown-item" type="button">
                            SOL
                          </button>
                        </div>
                      </div>
                      <ul>
                        <li class="w50">
                          <input type="text" name="" placeholder="Min" />
                        </li>
                        <li class="w50">
                          <input type="text" name="" placeholder="Max" />
                        </li>
                      </ul>
                    </div>

                    <div class="apply-button">
                      <button class="button">
                        <i class="reload-icon"></i>APPLY
                      </button>
                    </div>
                  </form>
                </div>

                <div class="col-md-9 col-lg-9">
                  <div class="dropdown select-down sortby">
                    <button
                      class="btn btn-secondary dropdown-toggle"
                      type="button"
                      id="dropdownMenu2"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Sort by latest
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenu2">
                      <button class="dropdown-item" type="button">
                        select
                      </button>
                      <button class="dropdown-item" type="button">
                        select
                      </button>
                      <button class="dropdown-item" type="button">
                        select
                      </button>
                      <button class="dropdown-item" type="button">
                        select
                      </button>
                      <button class="dropdown-item" type="button">
                        select
                      </button>
                    </div>
                  </div>

                  <div class="pro-lisiting">
                    <ul class="row">
                      <li class="col-md-4 col-lg-4">
                        <div class="pro-img">
                          <a href="https://opensea.io/assets/matic/0xcf4976a49027dbe5d89072e804072090481d78a1/3">
                            <img
                              src="http://sakosomonsters.com/images/marketplace/image-1.png"
                              alt=""
                            ></img>
                          </a>
                        </div>
                        <div class="pro-details">
                          <div class="pro-name">Sakoso Monster #3</div>
                          <div class="eth-value">
                            <span>2.49 ETH</span>
                          </div>
                          <div class="user">@wezcude</div>
                        </div>
                      </li>
                      <li class="col-md-4 col-lg-4">
                        <div class="pro-img">
                          <a href="https://opensea.io/assets/matic/0xcf4976a49027dbe5d89072e804072090481d78a1/6">
                            <img
                              src="http://sakosomonsters.com/images/marketplace/image-2.png"
                              alt=""
                            ></img>
                          </a>
                        </div>
                        <div class="pro-details">
                          <div class="pro-name">Sakoso Monster #6</div>
                          <div class="eth-value">
                            <span>2.49 ETH</span>
                          </div>
                          <div class="user">@wezcude</div>
                        </div>
                      </li>
                      <li class="col-md-4 col-lg-4">
                        <div class="pro-img">
                          <a href="https://opensea.io/assets/matic/0xcf4976a49027dbe5d89072e804072090481d78a1/4">
                            <img
                              src="http://sakosomonsters.com/images/marketplace/image-3.png"
                              alt=""
                            ></img>
                          </a>
                        </div>
                        <div class="pro-details">
                          <div class="pro-name">Sakoso Monster #4</div>
                          <div class="eth-value">
                            <span>2.49 ETH</span>
                          </div>
                          <div class="user">@wezcude</div>
                        </div>
                      </li>
                      <li class="col-md-4 col-lg-4">
                        <div class="pro-img">
                          <a href="https://opensea.io/assets/matic/0xcf4976a49027dbe5d89072e804072090481d78a1/2">
                            <img
                              src="http://sakosomonsters.com/images/marketplace/image-4.png"
                              alt=""
                            ></img>
                          </a>
                        </div>
                        <div class="pro-details">
                          <div class="pro-name">Coppa Sakosa</div>
                          <div class="eth-value">
                            <span>2.49 ETH</span>
                          </div>
                          <div class="user">@wezcude</div>
                        </div>
                      </li>
                      <li class="col-md-4 col-lg-4">
                        <div class="pro-img">
                          <a href="https://opensea.io/assets/matic/0xcf4976a49027dbe5d89072e804072090481d78a1/5">
                            <img
                              src="http://sakosomonsters.com/	images/marketplace/image-5.png"
                              alt=""
                            ></img>
                          </a>
                        </div>
                        <div class="pro-details">
                          <div class="pro-name">Sakoso Monster #5</div>
                          <div class="eth-value">
                            <span>2.49 ETH</span>
                          </div>
                          <div class="user">@wezcude</div>
                        </div>
                      </li>
                      <li class="col-md-4 col-lg-4">
                        <div class="pro-img">
                          <a href="https://opensea.io/assets/matic/0xcf4976a49027dbe5d89072e804072090481d78a1/8">
                            <img
                              src="http://sakosomonsters.com/images/marketplace/image-6.png"
                              alt=""
                            ></img>
                          </a>
                        </div>
                        <div class="pro-details">
                          <div class="pro-name">Sakoso Monster #8</div>
                          <div class="eth-value">
                            <span>2.49 ETH</span>
                          </div>
                          <div class="user">@wezcude</div>
                        </div>
                      </li>
                      <li class="col-md-4 col-lg-4">
                        <div class="pro-img">
                          <a href="https://opensea.io/assets/matic/0xcf4976a49027dbe5d89072e804072090481d78a1/7">
                            <img
                              src="http://sakosomonsters.com/images/marketplace/image-7.png"
                              alt=""
                            ></img>
                          </a>
                        </div>
                        <div class="pro-details">
                          <div class="pro-name">Sakoso Monster #7</div>
                          <div class="eth-value">
                            <span>2.49 ETH</span>
                          </div>
                          <div class="user">@wezcude</div>
                        </div>
                      </li>
                      <li class="col-md-4 col-lg-4">
                        <div class="pro-img">
                          <a href="https://opensea.io/assets/matic/0xcf4976a49027dbe5d89072e804072090481d78a1/1">
                            <img
                              src="http://sakosomonsters.com/images/marketplace/image-8.png"
                              alt=""
                            ></img>
                          </a>
                        </div>
                        <div class="pro-details">
                          <div class="pro-name">Two</div>
                          <div class="eth-value">
                            <span>2.49 ETH</span>
                          </div>
                          <div class="user">@wezcude</div>
                        </div>
                      </li>
                      <li class="col-md-4 col-lg-4">
                        <div class="pro-img">
                          <a href="https://opensea.io/assets/matic/0xcf4976a49027dbe5d89072e804072090481d78a1/0">
                            <img
                              src="http://sakosomonsters.com/images/marketplace/image-9.png"
                              alt=""
                            ></img>
                          </a>
                        </div>
                        <div class="pro-details">
                          <div class="pro-name">One</div>
                          <div class="eth-value">
                            <span>2.49 ETH</span>
                          </div>
                          <div class="user">@wezcude</div>
                        </div>
                      </li>
                    </ul>
                  </div>

                  <div class="pagination-div">
                    <ul class="pagination">
                      <li>
                        <a href="#" class="prev btn">
                          <img
                            src="http://sakosomonsters.com/old-static-page/assets/images/marketplace/page-arrow.png"
                            alt=""
                          ></img>
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0);">1</a>
                      </li>
                      <li class="active">
                        <a href="javascript:void(0);">2</a>
                      </li>
                      <li class="active">
                        <a href="javascript:void(0);">3</a>
                      </li>
                      <li>
                        <a href="javascript:void(0);">...</a>
                      </li>
                      <li>
                        <a href="#" class="next btn">
                          <img
                            src="http://sakosomonsters.com/old-static-page/assets/images/marketplace/page-arrow.png"
                            alt=""
                          ></img>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
