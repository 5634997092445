
import jQuery1 from "jquery";

export default function AccountOption({account}) {
    
    function copyAddressToClipboard(){
        if( navigator && navigator.clipboard ) {
            navigator.clipboard.writeText(account)
            jQuery1('button').tooltip({
                placement: 'bottom'
            });
        }
    }

    return (
        <div id="myWalletAccount" className="account-option-container text-left">
            <div className="wallter-info">
                <div className="wallet-address">
                    <span className="wallet-address-label">Persoal Wallet</span>
                    <span className="status">Connected</span>
                </div>
                <span className="wallet-address-value">{account}</span>
            </div>
            <hr className="divider" />
            <ul>
                <li>
                    <a>
                        <span onClick={ copyAddressToClipboard } className="custome-icon custome-icon-copy"></span> 
                        <span className="">Copy wallet address</span>
                    </a>
                </li>
                <li> 
                    <a>
                        <span className="custome-icon custome-icon-dollar"></span>
                        <span>Ethereum (ETH)</span>
                    </a>
                </li>
                <li>
                    <a>
                        <span className="custome-icon custome-icon-disconnect"></span>
                        <span>Disconnect</span>
                    </a>
                </li>
            </ul>
        </div>
    )

}
