
// old on Polygon Mumbai
// export const CONTRACT_ADDRESS="0x268AF5Bb20Ee42D28Bc2a453526591a7E57fDC94";

// new contract on Polygon mainnet
export const CONTRACT_ADDRESS="0xCF4976A49027DBE5D89072E804072090481d78A1";


// OpenSea integration
export const OPENSEA_ASSET_URL = "https://opensea.io/assets/sakoso-monsters";
export const CHOSEN_THEME = "true";
