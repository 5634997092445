import {useEffect, useState} from "react";
import {ethers} from "ethers";
import {ThirdwebSDK} from "@thirdweb-dev/sdk";
import Web3Modal from "web3modal";

import {CONTRACT_ADDRESS} from "./../constants.js";

export default function ClaimNFTs({account}) {

    const [mintedNFTs, setMintedNFTs] = useState([])
    const [quantity, setQuantity] = useState(1)
    const [claimedQty, setClaimedQty] = useState(0)
    const [totalSupply, setTotalSupply] = useState(0)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState()
    const mintPrice = 0.001

    useEffect(() => {
        if (quantity > 0) setError(null) // reset the error
    }, [quantity])

    useEffect( () => {
        loadClaimedStats()
    }, [])

    async function loadClaimedStats() {
        // const provider = new ethers.providers.JsonRpcProvider('https://polygon-mumbai.infura.io/v3/6f077298abb648b2adaa6cb1f8b91179')
        const provider = new ethers.providers.JsonRpcProvider('https://polygon-mainnet.infura.io/v3/38eaac8bbb6a4a189043c80684788054')
        // const sdk = new ThirdwebSDK(provider);
        // const provider = ethers.Wallet.createRandom();
        const sdk = new ThirdwebSDK(provider);
        const contract = sdk.getNFTDrop(CONTRACT_ADDRESS);
        const claimedNFTCount = await contract.totalClaimedSupply();
        setClaimedQty(parseInt(claimedNFTCount.toString()))

        const totalSupply = await contract.totalSupply();
        setTotalSupply(parseInt(totalSupply.toString()))
    }

    function reset() {
        setQuantity(1)
        setMintedNFTs([])
    }

    async function claim() {

        if (quantity <= 0) {
            setError("Please choose a quantity of 1 or more.")
            return
        }

        setLoading(true)

        // user signs
        const web3Modal = new Web3Modal()
        const connection = await web3Modal.connect()
        const provider = new ethers.providers.Web3Provider(connection)
        const signer = provider.getSigner()

        // Thirdweb SDK
        const sdk = new ThirdwebSDK(signer);
        const contract = sdk.getNFTDrop(CONTRACT_ADDRESS);

        // transactions (this will be a list)
        const tx = await contract.claimTo(account, quantity);

        let nfts = []
        for (let i=0; i < tx.length; i++) {
            const claimedNFT = await tx[i].data()
            let nft = {
                id: tx[i].id.toString(),
                receipt: tx[i].receipt,
                ...claimedNFT
            }
            nfts.push(nft)
        }
        setMintedNFTs(nfts)

        setLoading(false)
    }

    const progressBarWidth = Math.round(claimedQty/totalSupply * 100) + "%"
    const totalPrice = parseFloat(mintPrice * quantity).toFixed(3)

    return (
        <div className="container mintContainer text-center">

            <h4>Mint Your Sakoso Monster</h4>

            {error && <div className="bg-danger">
                {error}
            </div>}

            {mintedNFTs.length <= 0 &&
                <>
                    <p style={{fontSize: 18}}><strong>Amount</strong></p>

                    <button className="mintQtyButton" onClick={() => setQuantity(quantity - 1)}>
                        <i className="fa fa-minus"/>
                    </button>
                    <input className="mintQtyInput" type="number" value={quantity}
                           onChange={e => setQuantity(parseInt(e.target.value))}/>
                    <button className="mintQtyButton" onClick={() => setQuantity(quantity + 1)}>
                        <i className="fa fa-plus"/>
                    </button>

                    <br/>

                    <div className="price"> Total {totalPrice} ETH </div>
                    <br/>

                    <div className="mintProgress">
                        <div className="progress">
                            <div className="progress-bar" role="progressbar" style={{width: progressBarWidth}} aria-valuenow={claimedQty}
                                 aria-valuemin="0" aria-valuemax={totalSupply}></div>
                        </div>
                        <div className="progressLabel">{claimedQty}/{totalSupply} minted</div>
                    </div>

                    <button className="mintButton btn default-btn" onClick={() => {claim()}} disabled={loading}>Mint Now
                    </button>

                    {loading && <p>Loading...</p>}
                </>
            }

            {mintedNFTs.length > 0 &&
                <div className="row mintedRow">
                    <p>Here are your new monsters:</p>
                    {mintedNFTs.map((item, i) => (
                        <div key={i} className="col-4">

                            <div className="">
                                <img src={item.metadata.image} width="50" height="50" alt={item.metadata.name} />
                            </div>
                            #{item.id} {item.metadata.name}
                        </div>
                    ))}

                    <button className="btn btn-secondary btn-sm" onClick={() => reset()}>Back to the form</button>
                </div>
            }
        </div>
    )

}
